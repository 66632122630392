'use client';

import type { ReactNode } from 'react';
import classNames from 'classnames';
import type { Locale } from '@fxtr/i18n';
import type { ButtonSizes } from '@/components/atoms/Button';
import { Button } from '@/components/atoms/Button';
import type { VrmLookupModalTranslations } from '@/components/molecules/VrmLookupDumbModal/translations';
import type { VrmLookupFormTranslations } from '@/components/molecules/VrmLookupDumb/translations';
import type { MandatoryProp } from '@/types/react';
import { getPageOverviewLink } from '@/app/(landingPages)/(overview)/getPageOverviewLink';
import { VrmLookupDumbModal } from '../VrmLookupDumbModal';
import { VrmLookupDumb } from '../VrmLookupDumb';

export function ClientLookupCTA({
  locale,
  children,
  sku,
  campaignId,
  size = 'L',
  triggeredFrom,
  modalTranslations,
  formTranslations,
  className,
}: {
  readonly locale: Locale;
  readonly sku: MandatoryProp<string>;
  readonly campaignId: MandatoryProp<string>;
  readonly size?: ButtonSizes;
  readonly triggeredFrom: string;
  readonly modalTranslations: VrmLookupModalTranslations;
  readonly formTranslations: VrmLookupFormTranslations;
  readonly children: string;
  readonly className?: string;
}): ReactNode {
  return sku ? (
    <VrmLookupDumbModal
      className={classNames('cta-vrmlookup-modal', className)}
      size={size}
      triggeredFrom={triggeredFrom}
      {...modalTranslations}
      toggleButtonText={children}
    >
      <VrmLookupDumb locale={locale} sku={sku} campaignId={campaignId} {...formTranslations} />
    </VrmLookupDumbModal>
  ) : (
    <Button
      className={classNames('cta-services-overview', className)}
      size={size}
      href={getPageOverviewLink(locale, campaignId)}
    >
      {children}
    </Button>
  );
}

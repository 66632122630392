import { Locale } from '@fxtr/i18n';
import type { MandatoryProp } from '@/types/react';

export const OVERVIEW_LOCALE_PATHNAME = Object.freeze({
  [Locale.EN_GB]: '/services-overview',
  [Locale.FR_FR]: '/prestations',
}) satisfies Record<Locale, string>;

export const getPageOverviewLink = (
  locale: Locale,
  campaignId: MandatoryProp<string>,
  queryParams?: string | Record<string, string> | URLSearchParams
): string => {
  const pathname = OVERVIEW_LOCALE_PATHNAME[locale];
  const searchParams = new URLSearchParams(queryParams);

  if (campaignId) {
    /**
     * Display the promo banner on the page
     */
    searchParams.set('campaignId', campaignId);
    searchParams.set('promo', 'true');
  }

  const queryString = searchParams.toString();
  return `${pathname}${queryString ? `?${queryString}` : ''}`;
};
